






















import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "Directions"
})
export default class Directions extends Vue {
    private readonly locations: { [key: string]: string } = {
        "wagner": "Wagner Community Memorial Hospital Avera",
        "yankton": "Avera Sacred Heart Hospital",
        "sf": "Avera Breast Center Sioux Falls",
        "sf-2": "Edith Sanford Breast Center"
    }

    get place() {
        switch(this.$route.params.city.toLowerCase()){
        case "yankton":
            return "Avera+Sacred+Heart+Hospital";
        case "wagner":
            return "Wagner+Community+Memorial+Hospital+Avera";
        case "sf":
            return "Avera+Breast+Center+Sioux+Falls+(Louise+Ave)";
        case "sf-2":
            return "Edith+Sanford+Breast+Center";
        default:
            return undefined;
        }
    }

    get url() {
        return "https://www.google.com/maps/embed/v1/place?key=AIzaSyDKhKknIKheAA0KQjDpfsjPEWxlLW_mXUY&q=" + this.place;
    }
}
